































































import Vue from 'vue'

import { pick } from 'lodash'
import { User, VFormRef } from '@/types'
import { Accessors } from 'vue/types/options'
import { mapActions, mapGetters } from 'vuex'
import { getValidators } from '@/mixins/FormMixin'
import { MetaInfo } from 'vue-meta'
import { APP_META } from '@/consts'

const toUser = (user: User): Partial<User> => {
	return {
		...pick(user, ['email', 'iUserId', 'vFirstName', 'vLastName']),
		vMobileNumber: user.vMobileNumber?.toString(),
	}
}

export default Vue.extend({
	name: 'UpdateProfile',
	metaInfo(): MetaInfo {
		return {
			...APP_META,
			title: `Update Profile | ${APP_META.title}`,
		}
	},
	data: () => ({
		rules: getValidators('required', 'name', 'email', 'phone'),
		error: false,
		isLoading: false,
		isFormValid: false,
		user: {
			iUserId: -1,
			email: '',
			vFirstName: '',
			vLastName: '',
			vMobileNumber: '',
		} as Partial<User>,
	}),
	created() {
		this.user = toUser(this.$currentUser)
		this.$syncStack({
			name: 'Update profile',
			path: this.$route.path,
		})
	},
	computed: {
		...(mapGetters('auth', ['$isLoggedIn', '$currentUser']) as Accessors<{
			$isLoggedIn: boolean
			$currentUser: User
		}>),
		hasChanged(): boolean {
			return (
				JSON.stringify(this.user) !==
				JSON.stringify(toUser(this.$currentUser))
			)
		},
	},
	methods: {
		...mapActions('auth', ['updateProfile']),
		async onUpdateProfile() {
			if (!(this.$refs.formRef as VFormRef).validate()) return

			this.isLoading = true
			let [err] = await this.$store.dispatch('auth/updateProfile', {
				...this.user,
				vMobileNumber: this.user.vMobileNumber?.toString(),
			})
			this.isLoading = false

			if (err) {
				this.$toast.error('Error updating profile')
			} else {
				this.$toast.success('Profile updated successfully')
			}
		},
	},
})
